import { useEffect } from 'react';
import styles from './alertToast.module.css';
import '@/theme.module.css';

/**
 * Displays a customizable toast style alert message.
 *
 * @param {Object} config - The configuration object for this component.
 * @param {boolean} config.open - Flag indicating whether the alert should be displayed or not.
 * @param {function} config.closeAlert -The function to call to hide the alert.
 * @param {string} config.text - The text to display in the alert.
 * @param {string} config.type - Which type of alert should be displayed. Possible options are 'warning', 'info' or 'success'. 'info' is default.
 * @param {string} config.additionalClass - Optional additional styles.
 * @param {string} config.action - Optional action component.
 *
 * @example
 * <AlertToast open={alertToastOpen} closeAlert={() => setAlertToastOpen(false)} type={'success'} text={'Successfully saved.'} />
 */
export default function AlertToast({
  open,
  closeAlert,
  text,
  type,
  additionalClass,
  action,
}: {
  open: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeAlert: any;
  text: string;
  type: string;
  additionalClass?: string;
  action?: JSX.Element;
}) {
  const DEFAULT_TIMEOUT = 10000;
  let icon = 'info';

  if (type === 'error') {
    icon = 'error_outline';
  } else if (type === 'warning') {
    icon = 'error_outline';
  } else if (type === 'success') {
    icon = 'check';
  } else {
    icon = 'info';
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (open) {
      timeoutId = setTimeout(closeAlert, DEFAULT_TIMEOUT);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (!open || !text) {
    return;
  }

  return (
    <div className={styles.overlay}>
      <div className={[styles.container, additionalClass].join(' ')}>
        <div className={[styles.toast, styles[type]].join(' ')}>
          <span
            className={'material-symbols-outlined font32'}
            onClick={closeAlert}
          >
            {icon}
          </span>
          <div className="">{text}</div>
          {action && <div className="">{action}</div>}
          <span
            className={[
              'material-symbols-outlined font24',
              styles.closeIcon,
            ].join(' ')}
            onClick={closeAlert}
          >
            close
          </span>
        </div>
      </div>
    </div>
  );
}

export interface AlertToast {
  text: string;
  type: string;
  action?: JSX.Element;
}
