import { FC } from 'react';
import { Button } from '@/components/ui/button';
import Tooltip from '@/components/shared/tooltip';

export type PrefillButtonProps = {
  handlePrefill: (e: React.MouseEvent<HTMLElement>) => void;
  disableButton: boolean;
  prefillType: string;
};

const PrefillButton: FC<PrefillButtonProps> = ({ handlePrefill, disableButton, prefillType }) => {
  const tooltipMessage = `You've pre-filled data for ${prefillType}.`;
  return (
    <Tooltip message={tooltipMessage} disabled={!disableButton} position='auto'>
      <Button
        data-testid={'prefill-button'}
        className="w-[90px] bg-[#2C6DB7] gap-1 font-normal text-light-text-inverse py-1 pr-2 pl-2.5"
        onClick={handlePrefill}
        disabled={disableButton}
      >
        Pre-fill data
      </Button>
    </Tooltip>
  );
};

export { PrefillButton };
