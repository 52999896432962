import { useCallback, useEffect, useState } from 'react';
import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { Document, DocumentFieldType } from '@/models/document/document';
import { saveName } from '@/services/document/documentService';
import TextField from '@/components/shared/textField';
import Icon from '@/components/Icon';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import styles from './rename.module.css';
import '@/theme.module.css';
import { getCleanFileName } from '@/controllers/document/DocumentDialogController';

export default function Rename({
  selectedDocument,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
  refreshData,
  updateField,
}: {
  selectedDocument: Document;
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: DocumentFieldType,
    value: any
  ) => void;
}) {
  const [name, setName] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  // form input handlers
  const handleNameChange = useCallback(
    (value: string) => {
      const cleanValue = getCleanFileName(value);
      // alert user that special characters are being removed
      if (value !== getCleanFileName(value)) {
        showAlertToast(
          'info',
          `Detected invalid characters in file name, changed from: "${value}" to: "${cleanValue}".`
        );
      }

      setName(cleanValue);
    },
    [setName, showAlertToast]
  );

  useEffect(() => {
    if (open) {
      handleNameChange(selectedDocument.fileOriginalName);
    }
  }, [open]);

  const handleSave = useCallback(() => {
    setLoading(true);
    saveName(selectedDocument, name, authedFetch)
      .then((result: Document) => {
        closeDialog();
        showAlertToast('success', `File name changed to: "${name}"`);
        deselectAll();
        updateField(result.id, 'name', name);
        refreshData();
      })
      .catch(() => {
        showAlertToast('warning', `Error renaming file`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    authedFetch,
    closeDialog,
    deselectAll,
    name,
    refreshData,
    selectedDocument,
    showAlertToast,
    updateField,
  ]);

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Rename</h5>
        <div className={styles.closeIcon} onClick={closeDialog}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <form>
        <div className={styles.body}>
          <TextField
            label={'Document name'}
            theme={'crm'}
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            callback={handleSave}
            type={'primary'}
            theme={'crm'}
            disabled={!name}
            loading={loading}
          >
            Save
          </Button>
          <Button callback={closeDialog} type={'secondary'} theme={'crm'}>
            Cancel
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
