import { useEffect, useState, type FC } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { FormField } from '@/services/forms/models/formTypes';
import { useFormState, get, FieldError, useFormContext } from 'react-hook-form';
import { SubModelDialog } from '../SubModelDialog';

export type InputFieldProps = {
  field: FormField;
};

const SubmodelField: FC<InputFieldProps> = ({ field }) => {
  const { getValues, register, setValue, trigger, watch } = useFormContext();
  const { id, name, display, placeholder, syncWith, disabled, highlight } =
    field;
  const { errors, isValid } = useFormState();
  const error: FieldError = get(errors, name);
  const val = watch(name);

  type SubModelVehicle = {
    year: string | undefined;
    make: string | undefined;
    model: string | undefined;
    vehicle_number: number | undefined;
  };

  const [subModelOpen, setSubModelOpen] = useState<boolean>(false);
  const [subModelVehicle, setSubModelVehicle] = useState<
    SubModelVehicle | undefined
  >({
    year: '',
    make: '',
    model: '',
    vehicle_number: 0,
  });

  useEffect(() => {
    if (!isValid && field.validation?.required) {
      trigger(name);
    }
  }, [val]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, e.target.value || undefined, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    if (syncWith) {
      setValue(syncWith.fieldName, e.target.value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  const getVehicleNumber = (fieldName: string) =>
    parseInt(fieldName.split('.')[1]);

  const getVehicleData = (vehicleNumber: number) => {
    const vehicles = getValues('vehicles');
    return vehicles?.[vehicleNumber];
  };

  const handleSubModal = () => {
    const vehicleNumber = getVehicleNumber(field.name);
    const vehicleData = getVehicleData(vehicleNumber);

    if (vehicleData) {
      const { year, make, model } = vehicleData;
      setSubModelVehicle({
        year,
        make,
        model,
        vehicle_number: vehicleNumber,
      });
      setSubModelOpen(true);
    }
  };

  const assignSubModel = (
    vin: string,
    vehicle_number: number,
    subModel: string
  ) => {
    const vehicles = getValues('vehicles');
    if (vehicles && vehicles[vehicle_number]) {
      setValue(`vehicles.${vehicle_number}.vin`, vin, {
        shouldDirty: true,
      });
      setValue(`vehicles.${vehicle_number}.subModel`, subModel, {
        shouldDirty: true,
      });
    }
  };

  return (
    <div className="flex flex-col min-w-[236px] max-w-[492px] gap-1.5">
      <SubModelDialog
        open={subModelOpen}
        setOpen={setSubModelOpen}
        subModelVehicle={subModelVehicle}
        setSubModelVehicle={setSubModelVehicle}
        assignSubModel={assignSubModel}
      />
      <Label htmlFor={id || name}>
        <span className={highlight ? 'bg-yellow-200 px-2' : ''}>{display}</span>
      </Label>
      <Input
        {...register(name, {
          ...(field.validation ? { ...field.validation } : {}),
        })}
        type={'text'}
        id={id || name}
        placeholder={placeholder}
        data-testid={id || name}
        disabled={disabled}
        onChange={handleChange}
        onClick={handleSubModal}
      />
      {error && (
        <p className="text-sm text-light-text-error">{error.message}</p>
      )}
    </div>
  );
};

export { SubmodelField };
