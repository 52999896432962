import { Application } from '@bwinsurance/meta-rater-types';
import { ReactNode } from 'react';
import DisplayValue, { DisplayValueOptional } from '../DisplayValue';
import Section from '../Section';
import If from '../If';
import CoverageDetailsList from '../../components/CoverageDetailsList';
import { ClaimList } from '../ClaimList';
import SubheadingDivider from '../SubheadingDivider';
import { getYears } from '@/controllers/common/utils';
import { PriorPolicy } from '../PriorPolicyInfo';
import ErrorBoundary from '../ErrorBoundary';

const HomeInfo = ({
  application,
}: {
  application?: Application | null;
}): ReactNode => {
  if (!application) {
    return null;
  }
  const dataSource = application?.metadata?.canopyPullId
    ? 'Canopy Connect'
    : 'Intake Form';
  const streetAddress = application.home?.address?.lineOne
    ? `${application.home?.address?.lineOne} ${
        application.home?.address?.lineTwo ?? ''
      }`
    : undefined;
  return (
    <Section headingText="Property" materialIcon="home">
      <SubheadingDivider headingText="Address">
        <DisplayValueOptional label="Street" value={streetAddress} />
        <DisplayValueOptional
          label="City"
          value={application.home?.address?.city}
        />
        <DisplayValueOptional
          label="State"
          value={application.home?.address?.state}
        />
        <DisplayValueOptional
          label="Zip code"
          value={application.home?.address?.postalCode}
        />
        <DisplayValueOptional
          label="Years at address"
          value={getYears(application.home?.monthsAtAddress)}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Policy Info">
        <DisplayValue label="Source" value={dataSource} />
        <DisplayValueOptional
          label="Effective date"
          value={application?.effectiveDate}
          isDate
        />
        <DisplayValueOptional
          label="Estimated premium"
          value={application?.estimatedPremium}
          isCurrency
        />
        <DisplayValueOptional
          label="Multi-policy"
          value={application?.isMultiPolicy}
        />
        <DisplayValueOptional
          label="Policy type"
          value={application?.policy?.type}
        />
        <DisplayValueOptional
          label="Policy term"
          value={application?.policy?.term}
        />
        <DisplayValueOptional
          label="Business on premises"
          value={application?.home?.ratingInfo?.hasBusinessOnPremises}
        />
        <DisplayValueOptional
          label="Has trampoline"
          value={application?.home?.ratingInfo?.hasTrampoline}
        />
        <DisplayValueOptional
          label="Has dog on premises"
          value={application?.home?.ratingInfo?.hasDogOnPremises}
        />
        <DisplayValueOptional
          label="Dog breed"
          value={application?.home?.ratingInfo?.dogBreed}
        />
        <DisplayValueOptional
          label="Has dog bite history"
          value={application?.home?.ratingInfo.hasDogBiteHistory}
        />
        <DisplayValueOptional
          label="Has exotic animal on premises"
          value={application?.home?.ratingInfo?.hasExoticAnimalOnPremises}
        />
        <DisplayValueOptional
          label="Exotic animal species"
          value={application?.home?.ratingInfo?.exoticAnimalSpecies}
        />
        <DisplayValueOptional
          label="Has claim history"
          value={application?.hasClaimHistory}
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Construction">
        <DisplayValueOptional
          label="Year built"
          value={application?.home?.ratingInfo?.yearBuilt?.toString()}
        />
        <DisplayValueOptional
          label="Square feet"
          value={application?.home?.ratingInfo?.squareFeet}
        />
        <DisplayValueOptional
          label="Construction type"
          value={application?.home?.ratingInfo?.constructionType}
        />
        <DisplayValueOptional
          label="# of stories"
          value={application?.home?.ratingInfo?.numberOfStories}
        />
        <DisplayValueOptional
          label="# of bedrooms"
          value={application?.home?.ratingInfo?.numberOfBedrooms}
        />
        <DisplayValueOptional
          label="# of full bathrooms"
          value={application?.home?.ratingInfo?.numberOfFullBaths}
        />
        <DisplayValueOptional
          label="# of partial bathrooms"
          value={application?.home?.ratingInfo?.numberOfPartialBaths}
        />
        <DisplayValueOptional
          label="# of units"
          value={application?.home?.ratingInfo?.numberOfUnits}
        />
        <DisplayValueOptional
          label="Is new home"
          value={application?.residence?.isNewHome}
        />
        <DisplayValueOptional
          label="Roof type"
          value={application?.home?.ratingInfo?.roofType}
        />
        <DisplayValueOptional
          label="Roof shape"
          value={application?.home?.ratingInfo?.roofShape}
        />
        <DisplayValueOptional
          label="Dwelling use"
          value={application?.home?.ratingInfo?.dwellingUse}
        />
        <DisplayValueOptional
          label="# of fireplaces"
          value={application?.home?.ratingInfo?.numberOfFireplaces}
        />
        <DisplayValueOptional
          label="Property subtype"
          value={application?.home?.ratingInfo?.subType}
        />
        <DisplayValueOptional
          label="Heating type"
          value={application?.home?.ratingInfo?.heatingType}
        />
        <DisplayValueOptional
          label="Has swimming pool"
          value={application?.home?.ratingInfo?.hasSwimmingPool}
        />
        <If condition={Boolean(application?.home?.ratingInfo?.hasSwimmingPool)}>
          <DisplayValueOptional
            label="Is pool fenced"
            value={application?.home?.ratingInfo?.isSwimmingPoolFenced}
          />
          <DisplayValueOptional
            label="Pool type"
            value={application?.home?.ratingInfo?.swimmingPoolType}
          />
        </If>
        <DisplayValueOptional
          label="Solar panels"
          value={application?.home?.ratingInfo?.hasSolarPanels}
        />
        <DisplayValueOptional
          label="Electrical updates"
          value={application?.home?.ratingInfo?.electricalUpdate}
        />
        <DisplayValueOptional
          label="Plumbing updates"
          value={application?.home?.ratingInfo?.plumbingUpdate}
        />
        <DisplayValueOptional
          label="Heating updates"
          value={application?.home?.ratingInfo?.heatingUpdate}
        />
        <DisplayValueOptional
          label="Property class"
          value={application?.home?.ratingInfo?.class}
        />
      </SubheadingDivider>
      <PriorPolicy headingText="Existing Policy" application={application} />

      <SubheadingDivider headingText="Assessment Info">
        <DisplayValueOptional
          label="Assessed improvement value"
          value={application?.home?.ratingInfo?.assessedImprovementValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Assessed land value"
          value={application?.home?.ratingInfo?.assessedLandValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Assessed total value"
          value={application?.home?.ratingInfo?.assessedTotalValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Market improvement value"
          value={application?.home?.ratingInfo?.marketImprovementValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Market land value"
          value={application?.home?.ratingInfo?.marketLandValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Market total value"
          value={application?.home?.ratingInfo?.marketTotalValue}
          isCurrency
        />
        <DisplayValueOptional
          label="Purchase date"
          value={application?.home?.ratingInfo?.purchaseDate}
          isDate
        />
        <DisplayValueOptional
          label="Purchase price"
          value={application?.home?.ratingInfo?.purchasePrice}
          isCurrency
        />
      </SubheadingDivider>

      <SubheadingDivider headingText="Protective Devices">
        <DisplayValueOptional
          label="Smoke detector"
          value={
            application?.home?.ratingInfo?.protectiveDevices?.hasSmokeDetector
          }
        />
        <DisplayValueOptional
          label="Fire extinguisher"
          value={
            application?.home?.ratingInfo?.protectiveDevices
              ?.hasFireExtinguisher
          }
        />
        <DisplayValueOptional
          label="Fire alarm"
          value={application?.home?.ratingInfo?.protectiveDevices?.hasFireAlarm}
        />
        <DisplayValueOptional
          label="Burglar alarm"
          value={
            application?.home?.ratingInfo?.protectiveDevices
              ?.hasBurglarAlarmLocal
          }
        />
        <DisplayValueOptional
          label="Deadbolt locks"
          value={
            application?.home?.ratingInfo?.protectiveDevices?.hasDeadBoltLocks
          }
        />
      </SubheadingDivider>

      <CoverageDetailsList
        coverageDetailsList={application?.home?.coverage?.coverageDetails}
      />
      <ClaimList list={application?.claims ?? []} />
    </Section>
  );
};

export default function WrappedHomeInfo(props: {
  application?: Application | null;
}): ReactNode {
  return (
    <ErrorBoundary errorContext="Error in HomeInfo">
      <HomeInfo {...props} />
    </ErrorBoundary>
  );
}
