import { IntakeNavOptions } from '@/services/common/constants';
import { isPrefillIntegrationEnabled } from '@/services/common/config';
import { AgentIntakeForm } from '../agent/schema';
import { UseFormGetValues } from 'react-hook-form';

export const OVERRIDE_PREFILL_KEY = 'overridePrefill';

export const showPrefillBanner = (selectedKey: string): boolean => {
  const isSupportedKey =
    selectedKey === IntakeNavOptions.AUTO ||
    selectedKey === IntakeNavOptions.HOME;
  return isPrefillIntegrationEnabled && isSupportedKey;
};

export const getPrefillType = (selectedKey: string) => {
  const isHomeTab = selectedKey === IntakeNavOptions.HOME;
  const prefillType = isHomeTab ? 'property' : 'driver and vehicle';
  const prefillAddressType = isHomeTab ? 'property' : 'applicant';
  return { isHomeTab, prefillType, prefillAddressType };
};

export const checkRequiredFieldsForPrefill = (
  isHomeTab: boolean,
  getValues: UseFormGetValues<AgentIntakeForm>
) => {
  const requiredAutoFields = {
    'applicant.firstName': 'first name',
    'applicant.lastName': 'last name',
    'applicant.currentAddress.lineOne': 'street address',
    'applicant.currentAddress.stateCode': 'state',
  };
  const requiredPropertyFields = {
    'home.address.lineOne': 'street address',
    'home.address.stateCode': 'state',
  };
  const requiredFields = isHomeTab
    ? requiredPropertyFields
    : requiredAutoFields;
  const missingFields: string[] = [];
  for (const [key, value] of Object.entries(requiredFields)) {
    if (!getValues(key as keyof AgentIntakeForm)) {
      missingFields.push(value);
    }
  }
  return missingFields;
};


export const getMissingFieldsString = (
  isHomeTab: boolean,
  missingFields: string[]
) => {
  if (isHomeTab) {
    return `property's address`;
  }

  const missingName = missingFields.some(
    (field) => field === 'first name' || field === 'last name'
  );
  const missingAddress = missingFields.some(
    (field) => field === 'street address' || field === 'state'
  );
  if (missingName && missingAddress) {
    return `applicant's name and current address`;
  } else if (missingName) {
    return `applicant's name`;
  } else if (missingAddress) {
    return `applicant's current address`;
  }

  return '';
};

export const isPrefillButtonDisabled = (
  isHomeTab: boolean,
  formData: AgentIntakeForm | undefined
) => {
  const overridePrefill = localStorage.getItem(OVERRIDE_PREFILL_KEY);

  // if override flag exists in localStorage
  // do not disable prefill button
  if (overridePrefill) {
    return false;
  }

  return isHomeTab
    ? !!formData?.prefillStatus?.property
    : !!formData?.prefillStatus?.auto;
};
