import { Document, DocumentLinks } from '@/models/document/document';
import {
  getDocumentBlob,
  getDocumentLink,
} from '@/services/document/documentService';
import { DocumentUrl } from '@/models/document/documentUrl';
import { PolicyLink } from '@/models/document/policy';
import { OpportunityLink } from '@/models/document/opportunity';
import '@/theme.module.css';

export const botUser = 'Bot User';
export const timeFormat = new Intl.DateTimeFormat('en-US', {
  timeZone: 'UTC',
  month: '2-digit',
  day: '2-digit',
  year: '2-digit',
});

export const formatTerm = (
  effectiveDate: string,
  expirationDate: string
): string => {
  if (!effectiveDate || !expirationDate) {
    return '-';
  }

  return (
    timeFormat.format(new Date(effectiveDate)) +
    ' - ' +
    timeFormat.format(new Date(expirationDate))
  );
};

export const mapOpportunityList = (opportunity: OpportunityLink) =>
  ` •  ${opportunity.name}`;
export const mapPolicyList = (policy: PolicyLink) =>
  ` •  ${policy.policyNumber} (${formatTerm(
    policy.effectiveDate,
    policy.expirationDate
  )})`;

export const getLinksHover = (links: DocumentLinks): string => {
  const opportunityList = links?.opportunity ?? [];
  const policyList = links?.policy ?? [];

  let output = ``;

  if (opportunityList?.length) {
    output += `Associated opportunities:\n`;
    output += opportunityList.map(mapOpportunityList).join('\n');
  }

  if (opportunityList?.length && policyList?.length) {
    output += '\n';
  }

  if (policyList?.length) {
    output += `Associated policies:\n`;
    output += policyList.map(mapPolicyList).join('\n');
  }

  return output;
};

export const openDocumentLink = (url: string, filename: string) => {
  const link = window.document.createElement('a');
  link.href = url;
  link.download = filename;
  link.target = '_blank';
  link.click();

  window.URL.revokeObjectURL(url);
  link.remove();
};

export const viewDocument = async (
  document: Document,
  authedFetch: typeof fetch
) => {
  if (document.fileUrl) {
    //get link to file in blob storage
    const documentLink: DocumentUrl = await getDocumentLink(
      document.id,
      authedFetch
    );
    if (documentLink) {
      openDocumentLink(documentLink.url, document.fileOriginalName);
    }
  } else {
    //get file from ams
    const blob = await getDocumentBlob(document.id, authedFetch);
    const url = window.URL.createObjectURL(blob);
    openDocumentLink(url, document.fileOriginalName);
  }
};

export const addedBySortFunction = (
  a: any,
  b: any,
  sortField: string,
  order: string,
  defaultSort: (a: any, b: any) => number
) => {
  if (a[sortField] === botUser) {
    return order === 'asc' ? -1 : 1;
  } else if (b[sortField] === botUser) {
    return order === 'asc' ? 1 : -1;
  } else {
    return defaultSort(a, b);
  }
};
