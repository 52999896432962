import Button from '@/components/shared/button';
import Dialog from '@/components/shared/dialog';
import { Contact } from '@/models/forms/contact';
import { Select } from '@/components/shared/select';
import Icon from '@/components/Icon';
import styles from './EmailComponent.module.css';
import TextArea from '@/components/shared/textArea';
import { PolicyKeySet } from '../form/types';
import { FormOrigin } from '@/models/forms/form';
import { ListOption } from '@/models/common/options';

interface Props {
  resetThenClose(): void;
  handleEmailSelect(clickedEmail: ListOption): void;
  handlePersonalMessageChange(value: string): void;
  handleSendEmail(): void;
  emailOptionList: ListOption[];
  associatedContactList: Contact[];
  selectedEmail: string;
  personalMessage: string;
  selectedPolicyKeys: PolicyKeySet[];
  displayName: string;
  loading: boolean;
  open: boolean;
  origin: FormOrigin;
  invalidEmail: boolean;
  emailErrorMessage: string;
  emailValidating: boolean;
}

const EmailComponent: React.FC<Props> = ({
  resetThenClose,
  handleEmailSelect,
  handlePersonalMessageChange,
  handleSendEmail,
  emailOptionList,
  associatedContactList,
  selectedEmail,
  personalMessage,
  selectedPolicyKeys,
  displayName,
  loading,
  open,
  origin,
  invalidEmail = false,
  emailErrorMessage = '',
  emailValidating = false,
}) => {
  return (
    <Dialog open={open} theme={'crm'} width={800}>
      <div className={styles.header}>
        <h5>Select recipient</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <Select
          label={'Recipient'}
          theme={'crm'}
          options={emailOptionList}
          placeholder={
            associatedContactList && associatedContactList.length
              ? 'Select or enter an email address...'
              : `Enter customer's email address...`
          }
          updateValue={handleEmailSelect}
          combo={true}
          error={invalidEmail}
          errorMessage={emailErrorMessage}
          loading={emailValidating}
        />
        <TextArea
          label={'Personalized note (optional)'}
          value={personalMessage}
          theme={'crm'}
          placeholder={'Optional note'}
          onChange={(value: string) => handlePersonalMessageChange(value)}
        />
        <div className={styles.preview}>
          <label className={styles.label}>Preview (read-only)</label>
          <div className={styles.container}>
            <p>Hi {displayName},</p>
            {origin === 'user' && (
              <p>
                You contacted Brightway to make some changes. Use the link
                {selectedPolicyKeys.length > 1 ? 's' : ''} below to provide a
                few additional details and we'll handle the rest.
              </p>
            )}
            {origin === 'brightway' && (
              <>
                <p>
                  Your carrier has asked Brightway to let you know about an
                  urgent action needed. Please get this information to us within
                  the next two weeks.
                </p>
                <p>
                  Use the link{selectedPolicyKeys.length > 1 ? 's' : ''} below
                  to take care of this promptly and keep your policy active.
                </p>
              </>
            )}
            <p>
              {selectedPolicyKeys.map((policyKey: PolicyKeySet) => (
                <span>{policyKey.formName}</span>
              ))}
            </p>
            <p className={styles.personalNote}>{personalMessage}</p>
            <p>
              Tip: The link{selectedPolicyKeys.length > 1 ? 's' : ''} will
              expire in 24 hours. If this happens, simply follow the prompts to
              sign in using this email address.
            </p>
            <p>
              Thank you for being a part of the Brightway family
              <br />
              Brightway Customer Care Team
            </p>
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSendEmail}
          type={'primary'}
          theme={'crm'}
          disabled={!selectedEmail}
          loading={loading}
        >
          Send
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailComponent;
