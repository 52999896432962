import { UseFormReturn } from 'react-hook-form';
import type { AgentIntakeForm } from '@/services/forms/agent/schema';
import { PrefillResponse } from '@/services/forms/models/prefillTypes';

export const populateProperty = (
  formMethods: UseFormReturn<AgentIntakeForm>,
  data: PrefillResponse | null
) => {
  const results = data?.responses;

  if (results) {
    results.forEach((dataItem) => {
      if (dataItem.target === 'property') {
        const { ratingInfo } = dataItem.data;

        if (!ratingInfo || Object.keys(ratingInfo).length === 0) {
          return;
        }

        const setFieldValue = (field: any, value: any) => {
          const currentValue = formMethods.getValues(field);
          if (!currentValue) {
            formMethods.setValue(field, value?.toString());
          }
        };

        const formattedDate = ratingInfo.purchaseDate
          ? new Date(ratingInfo.purchaseDate).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : undefined;

        const currentValue = formMethods.getValues(
          'home.ratingInfo.purchaseDate'
        );
        if (!currentValue) {
          setFieldValue('home.ratingInfo.purchaseDate', formattedDate);
        }

        const fieldsToSet = {
          constructionType: ratingInfo.constructionType,
          dwellingType: ratingInfo.dwellingType,
          foundationType: ratingInfo.foundationType,
          heatingType: ratingInfo.heatingType,
          numberOfStories: ratingInfo.numberOfStories,
          roofShape: ratingInfo.roofShape,
          roofType: ratingInfo.roofType,
          squareFeet: ratingInfo.squareFeet,
          yearBuilt: ratingInfo.yearBuilt,
          numberOfFullBaths: ratingInfo.numberOfFullBaths,
          numberOfPartialBaths: ratingInfo.numberOfPartialBaths,
          numberOfUnits: ratingInfo.numberOfUnits,
          subType: ratingInfo.subType,
          class: ratingInfo.class,
        };

        Object.entries(fieldsToSet).forEach(([key, value]) => {
          setFieldValue(`home.ratingInfo.${key}`, value);
        });
      }
    });

    formMethods.setValue('prefillStatus.property', true);
  }
};
